import React from "react";

const Download = () => {

  return (
    <div style={{ background: "black" }}>
      <section class="hero-download">
        <h1>Discover the Future of Gaming</h1>
        <p>Play your favorite FPS gameand earn real rewards in the Qorbi World Play & Earb Airdrop. Are you ready to take your gaming to the next level?</p>

        <div class="buttons-container-download">
          <a href="download-link-1" class="cta-download button-download">Download On HyperPlay</a>
          <a href="download-link-2" class="cta-download button-download">Download On Elixir</a>
          <a href="https://medium.com/@qorbiworld/airdrop-alert-get-ready-to-play-rank-and-earn-3d83231b3dce" class="cta-download">Read Full Details</a>
        </div>
        <img src="https://digital.qorbiworld.com/12ONETAP_PLAY_AND_EARN_AIRDROP2.png" alt="I will forget I saw what was here before this text..." />
      </section>
      <section class="features-download">
        <div class="feature-card-download">
          <h3>Play Airdrop</h3>
          <p>A pure earning airdrop with no purchase needed. Earn with every match</p>
        </div>
        <div class="feature-card-download">
          <h3>To Qualify for Earning</h3>
          <p>Get at least 1 kill and complete a match. Huge max limits for rewards await!</p>
        </div>
        <div class="feature-card-download">
          <h3>Weekly In-Game Items Airdrops</h3>
          <p>Tap participants receive exclusive in-game items every week as part of our airdrop rewards program.</p>
        </div>
      </section>
      <section class="hero-download">
        <div class="buttons-container-download">
          <a href="https://discord.com/invite/tvHSEAubzn" class="cta-download">Join the Community</a>
          <a href="https://quest.playmana.gg/quests" class="cta-download">Start Questing Today</a>
          <a href="https://qorbiworld.com/market" class="cta-download">Shop our Earning Product</a>
        </div>
      </section>
    </div>
  );
};

export default Download;
